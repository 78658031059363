@mixin respond($breakpoint) {


    @if $breakpoint == small-phone {
        @media only screen and (max-width: 375px) { @content }; // 750px
    }

    @if $breakpoint == phone {
        @media only screen and (max-width: 46em) { @content }; // 750px
    }
    @if $breakpoint == phone-land {
        @media only screen and (max-width: 900px) and (max-height: 500px){ @content }; // 750px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; // 900px 56.25em
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; // 1200px
    }
    @if $breakpoint == mac {
        @media only screen and (max-width: 80em) { @content }; // 1280px
    }
    @if $breakpoint == laptop {
        @media only screen and (max-width: 90em) { @content }; // 1366px
    }
    @if $breakpoint == desktop125 {
        @media only screen and (max-width: 96em) { @content }; // 1366px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (max-width: 112.5em) { @content }; // 1800px
    }
    @if $breakpoint == all-desktop {
        @media only screen and (min-width: 85em) { @content }; //1360px+
    }
    @if $breakpoint == landscape-mobile {
        @media only screen and (orientation: landscape) { @content }; //1360px+
    }
}


body {
  background-color: #EEC970!important;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
}


img {
  max-width: 100%!important;
}

.font-rubik {
  font-family: 'Rubik', sans-serif!important;
}




// Header


.header-hero {
  height:100vh;
  background-image: url('./assets/herobg.png');
  padding-top: 50px;
  padding-left: 8vw;
  padding-right: 8vw;
  overflow: hidden;

  @include respond(phone) {
    padding-left: 4vw;
  padding-right: 4vw;
  }
}

.logo {
  margin-bottom: 10vh;
  @include respond(phone) {
    max-width: 40vw;
  }

  @include respond(desktop125) {
    margin-bottom: 5vh;
  }
}

.inovacije-tekst {
  @include respond(desktop125) {
    max-height: 120px;
  }

  @include respond(phone) {
    max-height: 80px;
  }
}

.line {
  width:234px;
  height: 3px;
  background: white;
  border-radius: 10px;
}

.hero-container {
  display: grid;
  grid-template-columns: 60vw 1fr;

  @include respond(phone) {
    grid-template-columns: 100%;
    margin-top: auto;
    padding: 10px;
    background: rgba(238, 165, 16, .4);
  }

  p {
    color: white;
    font-weight: bold;
    font-size: 27px;

    @include respond(desktop125) {
      font-size: 20px;
    }

    @include respond(phone) {
      font-size: 18px;
    }

    span {
      background-size: cover;
      padding: 6px 15px;
    }
  }
}

.astronaut {
  position: absolute;
  top: 40px;
  left:calc(60vw + 120px);

  @include respond(tab-land) {
    top: 120px;
  }

  @include respond(phone) {
    display: none;
  }

  // @include respond(phone) {
  //   display: none;
  // }

  img {
    max-height: 95vh;

    @include respond(phone) {
      max-height: 30vw;
    }
  }
}

.hero-button {
  border-radius: 50px;
  outline: 0;
  border: 0;
  overflow: hidden;
  background: #FD5353;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 23px;
  height: 69px;
  box-shadow: 0px 0px 34px 3px rgba(253,83,83,0.9);
  transition: .5s ease-in-out all;
  z-index: 3;
  margin-top: auto;


  @include respond(desktop125) {
    height: 50px;
    font-size: 18px;
  }

  @include respond(phone) {
    margin-bottom: 130px;
  }

  &:hover {
    transform: scale(1.1);
  }

  span {
    background: white;
    height: 79px;
    border-radius: 50px;
    padding: 13px;
    width: 100px;
    margin-left: 38px;
    margin-right: -7px;

    @include respond(desktop125) {
    height: 50px;
    width: 75px;
    margin-left: 20px;
  }
  }
}

.line-social {
  width: 1px;
  height: 100px;
  background: white;

  @include respond(desktop125) {
    height: 30px;
  }
}

.social-hero {
  position: absolute;
  bottom: 0;
}

.scroll-icon {
  position: absolute;
  bottom:100px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  @include respond(desktop125) {
    bottom: 30px;
  }

  @include respond(phone) {
    bottom: 70px;
  }

  img {
    height: 60px;
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);


    @include respond(phone) {
      height: 45px;

    }
  }
}

  .scroll-icon-wrap {
    display: none;
    width: 120px;
    height: 120px;
    background: white;
    border-radius: 100%;
  }

  @-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}

  .ping {
	-webkit-animation: ping 1.6s ease-in-out infinite both;
	        animation: ping 1.6s ease-in-out infinite both;
}



@media screen and (max-width:500px) {
    .atromobile {
      max-height: 90vh;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;

    }
}



.content-hero {
  @include respond(phone) {
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 760px;
  }
  @include respond(small-phone) {
    height: 550px;
  }
}





// Section 2

.section2 {
  overflow: hidden;

  min-height: 100vh;

  &__line {
    width: 3px;
    background: #FD5353;
    height: 400px;
    margin-bottom: 60px;

    @include respond(phone) {
      height: 300px;
    }
  }

  &__coverbg {
    position: absolute;
    top: -60px;
    left: 30px;
    @include respond(laptop) {
      width: 950px;
    }
    @include respond(tab-port) {
      left: 0;
    }

    @include respond(phone) {
      object-fit: cover;
      min-height: 100px;
      display: none;
    }
  }

  &__mainbg {

    @include respond(laptop) {
      width: 950px;
    }

     @include respond(phone) {
      width: 970px!important;
    }

    @include respond(phone) {
      object-fit: cover;
    }

  }

  &__container {
    max-width: 1140px;
    @include respond(phone) {
      min-height: 500px;
      max-width: 90%;
    }
  }

  &__pisma {
    position: absolute;
    top: -50px;
    right: 0;
  }

  &__bigcircle {
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        left: -41px;
  }

  &__malikrug {
    position: absolute;
    right: 200px;
    top: -20px;

    @include respond(phone) {
      top: -30px;
    }
  }

  &__text {
    position: absolute;
    top: 120px;
    padding: 0 140px;
    margin: 0 auto;

    @include respond(laptop) {
      top: 90px;
      padding: 0 130px;
    }

    @include respond(tab-land) {
      padding: 0 210px 0 110px;
    }

    @include respond(tab-port) {
      padding: 0 80px 0 80px;
      top: 60px;
    }

    @include respond(phone) {
      padding: 0 20px;
    }

    p {
      font-size: 20px;
      @include respond(laptop) {
        font-size: 18px;
      }

      @include respond(tab-land) {
        font-size: 16px;
      }
    }
  }

  &__figura {
    position: absolute;
    left: 200px;
    top: -90px;
  }

  .astronaut2 {
    position: absolute;
    right: 0;
    top: 400px;
    z-index: 33;

    @include respond(mac) {
      right: -20px;
    }

    @include respond(tab-port) {
      display: none;
    }

    img {
      max-height: 550px;
    }
}
}

// sekcija3

.section3 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1195px;
  margin-top: 100px;
  padding-top: 250px;
  max-width: 100%;
  overflow: hidden;

  @include respond(phone) {
    padding-top: 50px;
    min-height: 1030px;
  }
}

.section-universal {
  width: 1325px;
  max-width: 95%;
}

.sectionslikaleft {
  margin-right: 100px;
  @include respond(desktop125) {
    margin-right: 50px;
  }
  @include respond(tab-port) {
    margin: 0 auto;
  }

  img {
    @include respond(tab-port) {
      width: 350px;
      margin-bottom: 30px;
    }
  }
}

.sectionslikaright {
  margin-left: 100px;
  @include respond(desktop125) {
    margin-left: 50px;
  }
  @include respond(tab-port) {
    margin: 0 auto;
  }

  img {
    @include respond(tab-port) {
      width: 350px;
      margin-bottom: 30px;
    }
  }
}

.sectioncontent {
  width: 770px;
  max-width: 100%;
  position: relative;
}

.section-coverbg {
  position: absolute;
  top: -40px;
  left: 0;

  @include respond(tab-land) {
    height: 260px;
    width: 100%;
    top: -60px;
  }

  @include respond(phone) {
    display: none;
  }
}

.section-wrapbg {
  height: 240px;
  @include respond(tab-land) {
    height: 220px;
    width: 100%;
  }

  @include respond(phone) {
    height: 310px;
  }
}

.section-text {
  position: absolute;
  font-size: 20px;
  top: 49px;
  padding: 0 55px;
  margin-left: 20px;

  @include respond(desktop125) {
    top: 57px!important;
  }

  @include respond(laptop) {
    top: 50px;
    margin-top: 15px!important;
    font-size: 18px;
  }

  @include respond(mac) {
    top: 35px!important;
    font-size: 16px;
    margin-top: 0;
    transform: initial;
    left: 0;
  }

  @include respond(phone) {
    padding: 0 20px;
    max-width: 320px;
    top: 50%!important;
    left: 2%;
    transform: translateY(-50%);
  }
}

.section3-figure1 {
  position: absolute;
  top: -50px;
  right: 60px;
}

.section3-figure2 {
position: absolute;
bottom: 20px;
right: -20px;
}

.section-3-malikrug {
  position: absolute;
  bottom: -120px;
  right: 0;
}

.section-3-velikikrug {
  position: absolute;
  bottom: 60px;
  right: -130px;
}

//  section 4




.section4 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 517px;
  margin-top: 0px;
  padding-top: 0px;
  max-width: 100%;


   @include respond(phone) {
    padding-top: 0px;
  }
}


.section-coverbg-4 {
    position: absolute;
    top: -60px;
    left: -10px;
    @include respond(laptop) {
      width: 950px;
    }

    @include respond(tab-land) {
      top: -40px;
    }

    @include respond(tab-port) {
      left: 0;
    }

    @include respond(phone) {
      object-fit: cover;
      min-height: 100px;
      display: none;
    }
  }


.section4-figure1 {
  position: absolute;
  top: -150px;
  right: 60px;
}

.section4-figure2 {
position: absolute;
bottom: -110px;
right: -20px;
}

// section5

.section5 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1186px;
  margin-top: 50px;
  padding-top: 200px;
  max-width: 100%;

   @include respond(phone) {
    padding-top: 50px;
    min-height: 1020px;
  }
}

.section5-figure1 {
  position: absolute;
  top: -70px;
  left: 60px;
}

.section-5-velikikrug {
  position: absolute;
  bottom: 260px;
  left: -480px;

  @include respond(desktop125) {
    left: -520px;
  }
}

// section6

.section6 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 517px;
  margin-top: 0px;
  padding-top: 0px;
  max-width: 100%;

  @include respond(phone) {
    min-height: 100px!important;
    padding-top: 0;
  }
}


.section-coverbg-6 {
    position: absolute;
    top: -10px;
    left: -10px;
    @include respond(laptop) {
      top: -20px;
    }

    @include respond(tab-land) {
      top: -25px;
      height: 250px;
    }

    @include respond(tab-port) {
      left: 0;
    }

    @include respond(phone) {
      object-fit: cover;
      min-height: 100px;
      display: none;
    }
  }


.section6-figure1 {
  position: absolute;
  top: -150px;
  right: 260px;
}

.section6-figure2 {
position: absolute;
bottom: -90px;
right: -20px;
}


// section 7

.section7 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1186px;
  margin-top: 0px;
  padding-top: 500px;
  max-width: 100%;
  overflow: hidden;

  @include respond(phone) {
    padding-top: 50px;
    min-height: 750px;
  }
}

.section7-figure1 {
  position: absolute;
  top: -120px;
  right: 0;
}

// section 8

.section8 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 517px;
  margin-top: 0px;
  padding-top: 100px;
  max-width: 100%;

   @include respond(phone) {
    padding-top: 50px;
  }
}

.section-coverbg-8 {
    position: absolute;
    top: -75px;
    left: -10px;
    @include respond(laptop) {
      top: -75px;
    }

    @include respond(tab-land) {
      top: -75px;
      height: 250px;
    }

    @include respond(tab-port) {
      left: 0;
    }

    @include respond(phone) {
      object-fit: cover;
      min-height: 100px;
      display: none;
    }
  }


.section8-figure1 {
  position: absolute;
  top: -100px;
  left: 60px;
}

.section8-figure2 {
position: absolute;
bottom: -90px;
right: 20px;
}

.section-8-velikikrug {
  top: 35%;
  position: absolute;
  left: -160px;
}


// section9

.section9 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1100px;
  margin-top: 50px;
  padding-top: 200px;
  max-width: 100%;
  overflow: hidden;

  @include respond(phone) {
    padding-top: 50px;
  }
}

.section9-figure1 {
  position: absolute;
  top: -170px;
  right: 0;
}

.section9-figure2 {
position: absolute;
bottom: -290px;
right: 20px;
}

// section10

.section10 {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 517px;
  margin-top: 0px;
  padding-top: 0px;
  max-width: 100%;

   @include respond(phone) {
    padding-top: 50px;
  }
}

.section-coverbg-10 {
    position: absolute;
    top: -80px;
    left: -25px;

    @include respond(desktop125) {
      top: -85px!important;
    }

    @include respond(laptop) {
      top: -75px!important;
    }

    @include respond(tab-land) {
      top: -75px!important;
      height: 290px;
    }

    @include respond(tab-port) {
      left: 0;
    }

    @include respond(phone) {
      object-fit: cover;
      min-height: 100px;
      display: none;
    }
  }


.section10-figure1 {
  position: absolute;
  bottom: -110px;
  right: 20px;
}

.section10-figure2 {
position: absolute;
bottom: -90px;
right: 20px;
}

.section-8-velikikrug {
  top: 35%;
  position: absolute;
  left: -160px;
}

// footer

.footer {
  position: relative;
}

.footer-top {
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 1500px;
}

.planeta {
  margin-top: 150px;
  margin-bottom: 100px;

  img {
    margin-left: 300px;
    @include respond(phone) {
      margin-left: 0;
    }
  }
}

.footer-red {
  padding-top: 112px;
  background: #FD5353;
  width: 1296px;
  max-width: 90%;
  border: 1px solid white;
  border-bottom: none;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}


.footer-text {
  text-align: center;
  font-size: 24px;
  color: white;
  margin-bottom: -70px;
}

.astrounaut-footer {
  img {
    margin: 0 auto;
  }
  transform: translateY(40%);
}

.footer-padding {
  padding-left: 210px;
  padding-right: 210px;

  @include respond(tab-port) {
    padding-left: 5%;
    padding-right: 5%;
  }
}


.footer-two {
  display: flex;
  border-top: 1px solid white;
  @include respond(tab-port) {
    flex-direction: column;
  }
  .one {
    width: 50%;
    padding-left: 130px;
    padding-top: 70px;
    border-right: 1px solid white;
    @include respond(tab-port) {
      width: 100%;
      border: 0;
      padding-left: 0;
      text-align: center;
      margin-top: 100px;
    }

    h2 {
      color: white;
      font-size: 62px;
      @include respond(laptop) {
        font-size: 40px;
      }
    }
  }
  .two {
    padding-top: 230px;
    padding-left: 60px;
    @include respond(laptop) {
      padding-top: 170px;
    }
    @include respond(tab-port) {
      padding-top: 20px;
      margin-bottom: 20px;
      padding-left: 0;
      margin: 0 auto;
    }

    img {
      cursor: pointer;
      @include respond(laptop) {
        max-width: 300px!important;
        max-height: 50px;
      }
    }
  }
}

.footer-curved-line {
  top: 0;
  height: 200px;
  left: 140px;
  opacity: .5;
}

.footer-bottom {
  min-height: 570px;
  position: relative;
  overflow: hidden;

  @include respond(tab-port) {
    min-height: 350px;
  }
}

.top-extend {
  width: 1296px;
  max-width: 90%;
  height: 150px;
  background: #FD5353;
  border: 1px solid white;
  border-top: 0;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  @include respond(tab-port) {
    height: 50px;
  }
}

.line-center {
  width: 50%;
  height: 100%;
  border-right: 1px solid white;
}

.footer-bottom-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 158px;
  height: 158px;
  background: white;
  border-radius: 50%;
  top: 75px;
  @include respond(tab-port) {
    top: 0;
  }
}


.bottom-footer-logo {
  position: absolute;
  bottom: -30px;
  left: -241px;

  img{
    max-height: 380px;
  }
}

.footer-figure1 {
  bottom: -20px;
  left: 200px;
  @include respond(tab-port) {
    display: none;
  }
}

.footer-figure2 {
  top: 170px;
  right: -70px;
  @include respond(tab-port) {
    display: none;
  }
}

.ministarstvo {
  left: 50%;
  bottom: 80px;
  transform: translateX(-50%);
}

.social-footer {
  position: absolute;
  bottom: 0;
  right: 100px;
  @include respond(tab-port) {
    right: 10px;
  }
}


// sidebar

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  background: black;
  transition: 0.2s ease-in-out all;
  padding-left: 8vw;
  padding-right: 4vw;
  padding-top: 50px;
  overflow: hidden;


  @include respond(phone) {
    padding-left: 4vw;
  }
}


.sidebar-show {
  z-index: 14;
  opacity: 1;
  height: 100%;
  overflow-y: hidden;
}


.body-sidebar {
  height: 100vh;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}


.sidebar-line {
  position: absolute;
  top: -130px;
  left: -500px;
}

.sidebar-astronaut {
  position: absolute;
  right: 0;
  bottom: 0;

  img {
    max-height: 70vh;
  }

  @include respond(phone) {
    display: none;
  }
}

.menu-shape {
  position: absolute;
  bottom: -150px;
  right: -200px;
}

.sidebar-menu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    list-style: none;
  }
  div {
    position: relative;
    display: inline;
    &:hover {
      a {
        &:after {
          display: block;

        }
      }
    }
    a {
      color: white;
      font-size: 40px;
      font-weight: bold;
      display: inline;
      width: auto;
      text-decoration: none;
      text-transform: uppercase;
      @include respond(desktop125) {
        font-size: 30px;
      }
      @include respond(tab-port) {
        font-size: 20px;
      }

      &:after {
      content: '';
      display: none;
      width: 0;
      height: 10px;
      z-index: -1;
      background: red;
      position: absolute;
      left: -5px;
      top: 30px;
      -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

      @include respond(desktop125) {
        top: 25px;
      }

      @include respond(tab-port) {
        height: 5px;
        top: 15px;
      }
    }
    }

  }
}

.slide-left {
	-webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-left {
  0% {
    width: 0;
  }
  100% {
     width: calc(100% + 20px);
  }
}

.social-sidebar {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.main-menu {
  position: relative;
    top: 17px;
  @include respond(desktop125) {

    max-width: 100px;
  }

  @include respond(phone) {
    max-width: 50px;
    top: 10px;
  }
}

.color-red {
  color: #FD5353;
}

.color-green {
  color: #27B094;
}



.vibrate-1 {
	cursor:pointer;
  &:hover {
    -webkit-animation: vibrate-1 3s linear infinite both;
	        animation: vibrate-1 3s linear infinite both;
  }
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.planeta-animate {
  -webkit-animation: vibrate-1 5s linear infinite both;
	 animation: vibrate-1 5s linear infinite both;
}


.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: black;
    overflow-y: none;
    z-index: 1000;


    .close-modal {
      position: absolute;
      top: 50px;
      right: 100px;
      width: 50px;
      cursor: pointer;

      @include respond(phone) {
        right: 20px;
        top: 20px;
      }
    }
}

.cover-subpage {
  cursor: pointer;
  transition: .5s ease-in-out all;
  @include respond(desktop125) {
    max-height: 450px;
  }

}

.play-button {
  cursor: pointer;
  top: 50%;
  left:50%;
  width: 60px;
  transform: translate(-50%, -50%);
  transition: .5s ease-in-out all;

  @include respond(phone) {
    width: 30px;
  }
}

.subpage-info {
  padding-top: 260px;
  margin-top: -210px;
}

.subpage-box {
  background: white;
  border-radius: 30px;
  padding-left: 70px;
  padding-right: 70px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  overflow: hidden;
  position: relative;

  p {
    font-size: 18px;
    font-weight: 500;
  }

  @include respond(tab-port) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .figone {
    left: -40px;
    top: 320px;
    transform: rotateX(180deg);

    @include respond(tab-port) {
      display: none;
    }
  }

  .figtwo {
    right: 0px;
    top: 70px;
    @include respond(tab-port) {
      display: none;
    }

  }
}

h1 {
  @include respond(phone) {
    font-size: 26px!important;
  }
}

.color-white {
  color: white;
  text-decoration: none;
  &:hover {
    color: white;
  }
}